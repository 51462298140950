<template>
  <viewcard--c
    title="Nova Gravação"
    :btsave="null"
    :btdelete="null"
    :btback="{}"
    :busy="isLoading"
  >
    <MaterialUpload
      v-if="recording"
      :recording="recording"
      :isOperator="true"
      @getRecords="getRecord"
      :appointment="appointment"
    />
  </viewcard--c>
</template>
<script>
import MaterialUpload from "./components/material-upload/MaterialUpload.vue";
import _recordingService from "@/services/audiovisual/recording-media";
import _mediaFilesService from "@/services/audiovisual/recording-media-files";
import _appointmentService from "@/services/appointment-service";
import {
  BFormFile,
  BEmbed,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
} from "bootstrap-vue";

export default {
  components: {
    BFormFile,
    BEmbed,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    MaterialUpload,
  },
  data() {
    return {
      isLoading: false,
      recordingID: this.$route.params.id || null,
      teacherID: this.$route.query.teacher || null,
      appointmentID: this.$route.query.appointment || null,
      recording: null,
      appointment: null,
    };
  },
  created() {
    this.createRecording();
    this.getDetailsBaseAppointment();
  },
  methods: {
    getRecord() {
      if (this.recordingID) {
        this.isLoading = true;
        _mediaFilesService
          .findAllByRecording(this.recordingID)
          .then((res) => {
            if (res.content) {
              this.recording = {
                id: this.recordingID,
                mediaFiles: res.content,
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isLoading = false));
      }
    },
    createRecording() {
      if (!this.recordingID) {
        this.isLoading = true;
        let payload = {
          data: {
            teacher_id: this.teacherID,
            appointment_id: this.appointmentID,
          },
        };
        _recordingService
          .store(payload)
          .then((res) => {
            if (res.content) {
              this.recordingID = res.content.id;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => this.getRecord());
      }
    },
    getDetailsBaseAppointment() {
      if (this.appointmentID && this.appointmentID > 0) {
        this.isloading = true;
        _appointmentService
          .find_short(this.appointmentID)
          .then((res) => {
            this.appointment = { 
              ...res.content,
              appointmentId: parseInt(this.appointmentID),
            };
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
  },
};
</script>